import OpenAI from "openai";
import { encodingForModel } from "js-tiktoken";

import { isNumbered, numberingCompare, revise, isAnswer, hasSampleAnswer } from "./helpers.js";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_APIKEY,
  dangerouslyAllowBrowser: true,
});

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const getQuestionsByGrant = (grantIdentifier, questions) => {
  // console.log('getting to getQuestionsByGrant', grantId, questions);
  const matchedQuestions = questions.filter((question) => {
    if (!question?.fields?.grantIdentifier) return false;
    else return question.fields.grantIdentifier.split(",").includes(grantIdentifier);
  });

  // console.log('matchedQuestions', matchedQuestions);

  return matchedQuestions
};

const getAnswer = (answersArray, companyId, questionId, grantIdentifier) => {
  let possibleAnswers = answersArray.filter(
    (ans) =>
      ans?.fields?.AnswerText &&
      ans.fields?.CompanyID === companyId &&
      ans.fields.QuestionID === questionId &&
      ans.fields.grantIdentifier === grantIdentifier
  );
  if (possibleAnswers.length === 0) return null;
  else if (possibleAnswers.length === 1) {
    // if (!possibleAnswers[0]?.fields?.AnswerText || possibleAnswers[0].fields.AnswerText === "") return null;

    return possibleAnswers[0];
  } else {
    // console.log("There are multiple possible answers for question " + questionId + " from Company " + companyId + ".");
    // console.log(possibleAnswers);
    if (!possibleAnswers[0]?.fields?.AnswerText || possibleAnswers[0].fields.AnswerText === "") return null;
    return possibleAnswers[0];
  }
};

// NOTE : Requires that each question in questionsArray has an 'answer' property which contains the answer object.
//		  If no answer, then the answer property is null.
const getQuestionsAnswersSorted = (questionsArray) => {
  // console.log('getting to getQuestionsAnswersSorted', questionsArray)
  return questionsArray.sort((a, b) => {
    if (a.fields.Link && b.fields.Link);
    else if (a.fields.Link) return -1;
    else if (b.fields.Link) return 1;

    // Questions that have answers that need revision are at the top.
    if (revise(a.answer) && revise(b.answer));
    else if (revise(a.answer)) return -1;
    else if (revise(b.answer)) return 1;

    // Answers to Questions that are not numbered should always appear at the bottom
    if (!isNumbered(a?.fields?.QuestionText) && isAnswer(a.answer)) return 1;
    else if (!isNumbered(b?.fields?.QuestionText) && isAnswer(b.answer)) return -1;

    // Numbered Questions appear after un-numbered questions
    if (isNumbered(a?.fields?.QuestionText) && isNumbered(b?.fields?.QuestionText))
      return numberingCompare(a.fields.QuestionText, b.fields.QuestionText);
    else if (isNumbered(a?.fields?.QuestionText)) return 1;
    else if (isNumbered(b?.fields?.QuestionText)) return -1;

    // Answered Questions appear after un-answered questions
    if (!isAnswer(a.answer) && !isAnswer(b.answer)) return 0;
    else if (!isAnswer(a.answer)) return -1;
    else if (!isAnswer(b.answer)) return 1;

    return 0;
  });
};

const getQuestionsToDisplay = (answersArray, grantIdentifier, companyId, questions) => {
  return getQuestionsAnswersSorted(
    getQuestionsByGrant(grantIdentifier, questions).map((question) => {
      let newQuestion = question;
      newQuestion["answer"] = getAnswer(answersArray, companyId, question.fields.QuestionID, grantIdentifier);
      return newQuestion;
    })
  );
};


const getInitialAutodraftMessages = (companyRecord) => {
  return [
    {
      role: "system",
      content: `You will be given information about a company and then be asked to answer a question regarding the company. Answer the question from the perspective of the company.\r\n`,
    },
    {
      role: "user",
      content: `Name: ${companyRecord.fields.Name}. \r\n Employees: ${companyRecord.fields.Employees}. \r\n Country: ${companyRecord.fields.Country}. \r\n Province: ${companyRecord.fields.ProvinceOrState}. \r\n Industry: ${companyRecord.fields.Industry}. \r\n Founded: ${companyRecord.fields.Founded}. Profitable: ${companyRecord.fields["Profitable?"]}\r\n`,
    },
    {
      role: "user",
      content: `This is the description of the company named ${companyRecord.fields.Name}. \r\n Description: ${companyRecord.fields.Description} \r\n`,
    },
    { role: "user", content: `The following is additional information about the company.\r\n ${companyRecord.fields.LongDescription}` },
  ];
};

const autodraftAnswer = (companyRecord, question, pastAnswersContext) => {
  if (!question) {
    console.error("Invalid question");
    return;
  }

  const questionText = question.fields.QuestionText;

  const maxInputTokens = 16385 - 1000; // Change to match the model, -1000 to account for innaccuracies in tiktoken
  const model = 'gpt-4o';

  const questionMessages = pastAnswersContext?.length > 0 ? [
    { role: "user", content: `The following is a series of questions of the company and the company's answers:\r\n` },
    ...pastAnswersContext.map((q) => ({
      role: "user",
      content: `Question: ${q.question}. \r\n Answer: ${q.answer}`,
    })),
  ] : [];

  const initialMessages = getInitialAutodraftMessages(companyRecord);
  let questionToAskMessage = {
    role: "user",
    content: `Based on what you know about ${companyRecord.fields.Name}, answer this question: ${questionText}.`,
  };

  if (hasSampleAnswer(question)) {
    questionToAskMessage.content += `\n Please format your answer according to this sample answer: ${question.fields.SampleAnswer}`
  }

  let messages = [...initialMessages, ...questionMessages, questionToAskMessage];

  // remove enough context to not exceed maxInputTokens
  try {
    const encoder = encodingForModel(model);
    let tokenCount = encoder.encode(messages.map((message) => message.content).join("\n")).length;
    let numberOfQuestionsRemoved = 0;
    if (tokenCount > maxInputTokens) {
      
      while (tokenCount > maxInputTokens) {
        if (questionMessages.length === 0) {
          throw new Error(`Number of tokens required ${tokenCount} is larger than maximum input tokens ${maxInputTokens}`);
        }

        questionMessages.splice(0, 1);
        ++numberOfQuestionsRemoved;

        messages = [...initialMessages, ...questionMessages, questionToAskMessage];
        tokenCount = encoder.encode(messages.map((message) => message.content).join("\n")).length;
      }
    }

    console.log("context messages", questionMessages);
    console.log("we removed", numberOfQuestionsRemoved, "questions");
  } catch (e) {
    console.error("Error:", e);
  }

  try {
    const chatCompletion = openai.chat.completions.create({
      model: model,
      messages: messages,
    });

    return chatCompletion;
  } catch (e) {
    console.error(e);
    return "N/A";
  }
};

const getAutodraftQuestionsText = (companyRecord, questions, pastAnswersContext) => {
  return Promise.all(
    questions.map((q) => {
      if (isAnswer(q?.answer)) {
        const qText = `<strong>${q.fields.QuestionText}</strong>\nAnswer: ${q.answer.fields?.AnswerText}`;
        return qText;
      } else {
        // const element = document.getElementById(q.fields.QuestionID);

        return new Promise((resolve, reject) => {
          autodraftAnswer(companyRecord, q, pastAnswersContext)
            .then((data) => {
              const qAnswer = data.choices[0].message.content;
              // element.value = qAnswer;
              resolve(
                `<strong>${q.fields.QuestionText}</strong>\nAnswer: <p style="color: red; display: inline-flex;">[ First Draft ]<p> ${qAnswer}`
              );
            })
            .catch((error) => {
              console.error(`Error in autodrafting answer for question ${q.fields.QuestionText}, ${q.fields.QuestionID}`, error);
              reject(error);
            });
        });
      }
    })
  );
};

const handleAutodraftGrant = async (grantRecord, companyRecord, questions, clientEmail, pastAnswersContext) => {
  // TODO : Add this env variable so it works on local
  const webhookURL = `https://hooks.zapier.com/hooks/catch/16093819/${process.env.REACT_APP_ZAPIER_AUTODRAFT}/`;

  const questionsText = (await getAutodraftQuestionsText(companyRecord, questions, pastAnswersContext)).join("\n\n");

  fetch(webhookURL, {
    method: "POST",
    body: JSON.stringify({
      grant: grantRecord.fields.Name,
      amount: grantRecord.fields.AnnualMargin,
      company: companyRecord.fields.Name,
      questions: questionsText,
      clientEmail: clientEmail
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(JSON.stringify(response));
      }

      return response.json();
    })
    .then((data) => console.log("data", data))
    .catch((e) => console.error("Error in sending autodraft request: ", e))
};

export { getQuestionsToDisplay, handleAutodraftGrant };