// Sample questions and answers for auto-drafting
export const processedQuestions = [
  {
    _table: {
      _base: {
        _airtable: {},
        _id: "appVulceDQ4SKlQoP",
      },
      id: null,
      name: "questions",
    },
    // ... rest of question objects
  }
];

// Default email if none provided
export const defaultEmail = "will@getupgraded.ca";

// Past answers context for reference
export const pastAnswersContext = [
  {
    question: "About the company max 200 words",
    answer: "changing answer text",
  },
  {
    question: "Link to Shared Google Doc",
    answer: "https://www.google.com/",
  }
];

// Company information
export const companyInfo = {
  name: "Upgraded",
  address: "2631 W 2nd Ave., Vancouver, BC, Canada, V6K 1K1",
  phone: "(778) 889-3825",
  position: "CEO",
  fullTimeEmployees: 9,
  industry: "Software and Technology",
  incorporated: true,
  description: "Upgraded is a profitable software development company based in British Columbia, Canada. Founded in 2022, Upgraded specializes in helping software, hardware, and venture-backed startups claim tax credits, grants, technology savings, and other often-overlooked financial opportunities."
};

// Project details
export const projectDetails = {
  workspace: {
    location: "2631 W 2nd Ave., Vancouver, BC, Canada, V6K 1K1",
    inPersonAvailable: true,
    hybridAvailable: true
  },
  desiredSkills: [
    "Marketing Research",
    "Marketing Assistance",
    "Social Media Management",
    "Sales",
    "Technical Research",
    "HTML & CSS",
    "UX Design",
    "UI Design",
    "APP Development",
    "JAVA",
    "PYTHON",
    "Graphic Design",
    "Content Writing",
    "Excel",
    "Presentation Preparation"
  ]
};

// Function to handle auto-drafting of grants
export const handleAutodraftGrant = async (
  grantRecord,
  company,
  processedQuestions,
  email,
  pastAnswersContext
) => {
  // Implementation of autodraft handling
  // This would need to be moved from wherever it currently exists
};